*,
::after,
::before {
  box-sizing: border-box;
}
@font-face {
  font-family: "Segoe Pro Display Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe Pro Display Regular"),
    url("assets/fonts/SegoeProDisplay-Regular.woff") format("woff");
}
:root {
  --primary-color: #3a4654; /* Main primary color */
  --secondary-color: #354961; /* Secondary color */

  --text-color: #333333; /* Text color - dark gray */
  --background-color: #e2e5e9; /* Background color - light gray */
  --background-primary: #fcfefd; /* Background color - light gray */
  --link-color: #007acc; /* Link color - blue */
  --accent-color: #23a65d; /* Accent color - coral */
}
body {
  font-family: Segoe Pro Display Regular, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}
p,
ul,
li,
ol,
h1,
h2 {
  max-width: 700px;
}
h1,
h2,
h3 {
  color: var(--primary-color);
}
.nobreak {
  white-space: nowrap;
}
main ol,
main ul {
  padding-left: 20px;
}
main ol li,
main ul li {
  margin-bottom: 10px;
}
a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s ease;
}
a:hover {
  color: var(--accent-color);
}
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.button:hover {
  background-color: var(--primary-color);
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bt {
  border-top: 10px solid var(--background-color);
}
.bl,
.blr {
  border-left: 5px solid var(--background-color);
}
.br,
.blr {
  border-right: 5px solid var(--background-color);
}
.blr:first-child {
  border-left: 10px solid var(--background-color);
}
.blr:last-child {
  border-right: 10px solid var(--background-color);
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.w25 {
  width: 25%;
}
.spacer {
  flex-grow: 1;
}
.wrap {
  max-width: 1200px;
  margin: 0 auto;
}
.darken {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #7d7d7d63;
  backdrop-filter: blur(1px);
}
.box img {
  width: 100%;
}
.box {
  margin: 10px;
  overflow: hidden;
  background: #ffffff;
}
.box .inner {
  padding: 20px 20px;
}
.box h2 {
  margin: 10px 0;
  font-size: 20px;
}
.box h3 {
  margin: 15px 0;
  font-size: 17px;
}
.box p {
  margin: 10px 0;
}
.box .image {
  background: white;
  text-align: center;
}

.box img {
  height: 240px;
  width: auto;
}
img.fw16x9 {
  width: 100%;
  aspect-ratio: 16/9;
  height: auto;
  max-width: 700px;
  margin: 10px 0;
}
@media screen and (min-width: 600px) {
  .flex-large {
    display: flex;
  }
  main .grid2 {
    display: grid;
    grid-template-areas:
      "a b b"
      "a c c"
      "d e e"
      "d f f";
    grid-template-columns: 50% 25% 25%;
    grid-auto-flow: dense;
  }
  .box:nth-child(1) {
    grid-area: a;
  }
  .box:nth-child(2) {
    grid-area: b;
  }
  .box:nth-child(3) {
    grid-area: c;
  }
  .box:nth-child(4) {
    grid-area: d;
  }
  .box:nth-child(5) {
    grid-area: e;
  }
  .box:nth-child(6) {
    grid-area: f;
  }
}
