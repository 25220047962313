.grid-profile {
  display: grid;
  grid-auto-columns: 1fr 1.66fr;
  grid-auto-flow: column;
  background: var(--background-color);
}
img.logo {
  height: 40px;
  margin: 20px;
}
.menu {
  font-size: 31px;
  margin: 25px;
  color: var(--secondary-color);
  cursor: pointer;
}
header {
  background-color: var(--background-primary);
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  -webkit-box-shadow: 0px 10px 0px 0px var(--background-color);
  -moz-box-shadow: 0px 10px 0px 0px var(--background-color);
  box-shadow: 0px 10px 0px 0px var(--background-color);
}
body {
  padding-top: 80px;
}
header nav {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow-y: auto;
}
nav {
  background: var(--background-color);
}

ul.nav {
  list-style: none;
  margin: 0 10px;
  padding: 0;
  background-color: var(--background-primary);
}
ul.nav li {
  padding: 10px 20px;
}
ul.nav .menu-cat {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--secondary-color);
  margin-top: 10px;
}
img.portrait {
  border-radius: 50%;
  padding: 20px;
  height: 141px;
  width: 141px;
}
nav .w25 {
  text-align: center;
  border-left: 3px solid var(--background-color);
  background-color: var(--background-primary);
}
nav .w25 a {
  color: var(--secondary-color);
  display: block;
}
nav .w25 svg {
  padding: 20px;
  height: 24px;
}
.callout {
  padding: 20px 30px 20px 10px;
}

@media screen and (min-width: 600px) {
  header .nav-content {
    max-width: 400px;
    background: var(--background-color);
    float: right;
    padding-bottom: 10px;
  }
  header nav {
    background: transparent;
  }
  .menu-trigger {
    background: var(--background-color);
  }
}
