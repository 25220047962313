footer {
  padding: 10px 0 20px 0;
}
footer .wrap {
  padding: 0 20px;
}
.footer-title {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 30px;
  text-transform: uppercase;
  font-size: 14px;
}
footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
footer li {
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  word-wrap: break-word;
  margin-bottom: 14px;
  padding-right: 5px;
}
footer .rights {
  margin-top: 40px;
}
