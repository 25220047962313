.google-reviews img {
  width: 97px;
  height: auto;
}
.star.active {
  color: #f9bc15;
  font-size: 30px;
}
.google-reviews {
  padding: 20px;
}
.average-rating p {
  margin-top: 0;
  font-size: 13px;
}
.reviews-link {
  padding-top: 10px;
}
