.template-sidebar {
  display: flex;
  flex-direction: column-reverse;
}

.template-sidebar ul.nav li {
  padding: 10px;
}
.template-sidebar .box h2 {
  margin: 30px 0 10px 0;
}
.box .inner h1:nth-child(1) {
  margin-top: 10px;
}
.template-sidebar .sidebar {
  padding-bottom: 10px;
}

@media screen and (min-width: 600px) {
  .template-sidebar {
    flex-direction: initial;
  }
  .template-sidebar ul.nav li {
    padding: 10px;
  }
  .template-sidebar .sidebar {
    width: 290px;
  }
  .template-sidebar .content {
    width: calc(100% - 290px);
  }
  .template-sidebar .box .inner {
    padding: 20px 30px;
  }
}

@media screen and (min-width: 750px) {
  .template-sidebar ul.nav li {
    padding: 10px 20px;
  }
  .template-sidebar .sidebar {
    width: 320px;
  }
  .template-sidebar .content {
    width: calc(100% - 320px);
  }
}
@media screen and (min-width: 900px) {
  .template-sidebar .box .inner {
    padding: 20px 50px;
  }
  .box .inner h1:nth-child(1) {
    margin-top: 30px;
  }
}
